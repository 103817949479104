import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { endpoints } from "api"
import type { AxiosError } from "axios"

export interface ServiceStatusResponse {
  data: string
}

export interface ServiceStatusRequest {
  value: string
}

export function useServiceStatus() {
  return useQuery({
    queryKey: ["service-status"],
    queryFn: async () => {
      return endpoints.customerSupport.getServiceInformation().get()
    },
  })
}

export function useUpdateServiceStatus(options?: {
  onSuccess?: (data: ServiceStatusResponse) => void
  onError?: (error: AxiosError) => void
}) {
  const queryClient = useQueryClient()
  return useMutation(
    async (payload: ServiceStatusRequest) => {
      const response = await endpoints.customerSupport
        .updateServiceInformation()
        .post(payload)
      queryClient.invalidateQueries({ queryKey: ["service-status"] })
      return response.data
    },
    {
      onSuccess: options?.onSuccess,
      onError: options?.onError,
    }
  )
}
