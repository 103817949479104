import type { TextFieldProps } from "@mui/material"
import { TextField } from "@mui/material"
import type {
  ControllerProps,
  FieldError,
  FieldValues,
  ValidationRule,
} from "react-hook-form"
import { Controller } from "react-hook-form"

type LiftedProps =
  | "label"
  | "sx"
  | "children"
  | "helperText"
  | "disabled"
  | "hidden"
  | "onChange"

export type ControlledTextFieldProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  "render"
> &
  Pick<TextFieldProps, LiftedProps> & {
    fieldProps?: Omit<TextFieldProps, LiftedProps | keyof ControllerProps<T>>
  }

const getErrorMessage = (
  error?: FieldError,
  label?: React.ReactNode,
  maxLength?: ValidationRule<number>
) => {
  if (!error) return undefined
  if (error.message) return error.message

  const messagesByType: { [type: string]: string } = {
    required: `${label || "Field"} cannot be blank`,
    maxLength: `${label || "Field"} must be less than ${maxLength} chars`,
  }
  const message = messagesByType[error.type]

  return message || "Unknown error"
}

export function ControlledTextField<T extends FieldValues>({
  name,
  label,
  sx,
  fieldProps,
  children,
  helperText,
  rules,
  onChange,
  disabled,
  hidden,
  ...controllerProps
}: ControlledTextFieldProps<T>) {
  const { maxLength } = rules || {}
  return (
    <Controller
      {...controllerProps}
      rules={rules}
      name={name}
      render={({ field, fieldState }) => (
        <TextField
          {...fieldProps}
          {...field}
          onChange={(event) => {
            onChange?.(event)
            field.onChange(event)
          }}
          children={children}
          disabled={disabled}
          hidden={hidden}
          sx={sx}
          label={label}
          helperText={
            getErrorMessage(fieldState.error, label, maxLength) || helperText
          }
          error={Boolean(fieldState.error)}
        />
      )}
    />
  )
}
