import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material"
import { CollapsibleCard, SimpleGrid } from "components"
import useRiskMapping from "pages/Credit/hooks/useCreditRiskMapList"
import AddIcon from "@mui/icons-material/Add"
import { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import type { RiskMappingData } from "api"
import { routes } from "routes"
import COLUMNS from "pages/Credit/RiskMapList.columns"
import type { RiskListRow } from "pages/Credit/RiskMapList.columns"

const loadRiskData = (data: RiskMappingData, product: string) => {
  const rows = []
  for (const [type, value] of Object.entries(data)) {
    for (const [name, versions] of Object.entries(value)) {
      rows.push({
        key: product + type + name,
        versions,
        product,
        type,
        name,
      })
    }
  }
  return rows
}

export default function RiskMapList() {
  const navigate = useNavigate()

  const [product, setProduct] = useState<string>("sdl")
  const [mappingType, setMappingType] = useState("interest_rate")

  const { data: loanData = {}, isLoading } = useRiskMapping("sdl")
  const { data: cardData = {} } = useRiskMapping("credit")

  const rows = useMemo(() => {
    let rows: RiskListRow[] = []
    rows = rows.concat(loadRiskData(loanData, "sdl"))
    rows = rows.concat(loadRiskData(cardData, "credit"))
    rows = rows?.map((r) => ({ actions: r, ...r }))
    return rows
  }, [cardData, loanData])

  return (
    <Grid item xs={16}>
      {isLoading ? (
        <Skeleton role="progressbar" height={600} sx={{ transform: "none" }} />
      ) : (
        <>
          <CollapsibleCard title="Create new Mapping" type="main">
            <Grid container spacing={2}>
              <Grid item>
                <FormControl sx={{ width: 150 }}>
                  <InputLabel id="product-select-label">Product</InputLabel>
                  <Select
                    labelId="product-select-label"
                    id="product-select"
                    value={product}
                    label="Product Name"
                    onChange={(event) => setProduct(event.target.value)}
                  >
                    {["sdl", "credit"].map((value) => (
                      <MenuItem key={`${product}_value`} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl sx={{ width: 150 }}>
                  <InputLabel id="type-select-label">Mapping Type</InputLabel>
                  <Select
                    labelId="type-select-label"
                    id="type-select"
                    value={mappingType}
                    label="Mapping Type"
                    onChange={(event) => setMappingType(event.target.value)}
                  >
                    {Object.keys(product === "sdl" ? loanData : cardData).map(
                      (value) => (
                        <MenuItem key={`${mappingType}_value`} value={value}>
                          {value}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  onClick={() =>
                    navigate(
                      routes.riskMapCreate({ product, type: mappingType })
                    )
                  }
                  variant="contained"
                  style={{ height: "100%" }}
                  disabled={product === undefined || mappingType === undefined}
                >
                  <AddIcon />
                </Button>
              </Grid>
            </Grid>
          </CollapsibleCard>

          <CollapsibleCard title="Mapping List" type="main">
            <SimpleGrid
              idField={"key"}
              rows={rows}
              columns={COLUMNS}
              quickFilter
              pagination
              disableColumnFilter={true}
              rowsPerPageOptions={[100, 500, 99999]}
            />
          </CollapsibleCard>
        </>
      )}
    </Grid>
  )
}
