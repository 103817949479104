import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"
import CompanyUpdateIcon from "@mui/icons-material/ChangeCircle"
import { useState } from "react"
import { useParams } from "react-router-dom"
import type { CreditConfig, RiskMapping, RiskMappingData } from "api"
import { useNotification } from "context/notification"
import ButtonWithConfirmation from "components/ButtonWithConfirmation/ButtonWithConfirmation"
import useCreditCompanyConfig from "pages/Credit/hooks/useCreditCompanyConfig"

export default function CompanyConfigUpdateDialog({
  riskMapping,
  mappingList,
}: {
  riskMapping: RiskMapping
  mappingList: RiskMappingData
}) {
  const { product = "", type = "" } = useParams()
  const notify = useNotification()
  const { mutateAsync } = useCreditCompanyConfig()
  const [mappingName, setMappingName] = useState<string>("")
  const [isLoading, setLoading] = useState(false)
  const [mappingVersion, setMappingVersion] = useState<string>("")
  const [showDialog, setShowDialog] = useState(false)
  const show = () => setShowDialog(true)
  const cancel = () => setShowDialog(false)
  const confirm = async () => {
    setLoading(true)
    const data: CreditConfig = { sdl_risk_mapping: {} }
    if (product === "sdl") {
      data.sdl_risk_mapping[type] = {
        name: mappingName,
        version: mappingVersion,
      }
    }
    for (const company of riskMapping?.companies ?? []) {
      await mutateAsync({ id: company.company_id, data })
    }
    notify.success(
      `config updated for ${riskMapping?.companies?.length} companies`
    )
    setLoading(false)
    setShowDialog(false)
    setMappingName("")
    setMappingVersion("")
  }
  return (
    <>
      <Button
        style={{ height: "100%" }}
        variant="contained"
        endIcon={<CompanyUpdateIcon />}
        onClick={show}
      >
        Bulk Update Companies
      </Button>
      <Dialog open={showDialog}>
        <DialogTitle id="confirmation-dialog">
          {"Company Risk Map Config Update"}
        </DialogTitle>
        <DialogContent>
          <br />
          <Grid container spacing={10}>
            <Grid item>
              <FormControl sx={{ width: 150 }}>
                <InputLabel id="name-select-label">Mapping Name</InputLabel>
                <Select
                  labelId="name-select-label"
                  id="name-select"
                  value={mappingName}
                  label="Mapping Name"
                  onChange={(event) => {
                    setMappingName(event.target.value)
                    setMappingVersion("")
                  }}
                >
                  {Object.keys(mappingList[type]).map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl sx={{ width: 150 }}>
                <InputLabel id="name-select-label">Mapping Version</InputLabel>
                <Select
                  labelId="version-select-label"
                  id="version-select"
                  value={mappingVersion}
                  label="Mapping Version"
                  disabled={mappingName === ""}
                  onChange={(event) => setMappingVersion(event.target.value)}
                >
                  {mappingName !== ""
                    ? mappingList[type][mappingName].map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" disabled={isLoading} onClick={cancel}>
            Cancel
          </Button>
          <ButtonWithConfirmation
            loading={isLoading}
            style={{ height: "100%" }}
            label="Apply"
            title="Confirm"
            variant="contained"
            message="Are you sure you want to apply these changes?"
            onConfirm={() => {
              confirm()
              return true
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
