import { useParams } from "react-router-dom"
import useCreditRiskMap from "pages/Credit/hooks/useCreditRiskMap"
import { RiskMapEditor } from "pages/Credit/components/RiskMapEditor"

export default function RiskMapping() {
  const {
    product = "sdl",
    type = "decision",
    name = "A",
    version,
  } = useParams()

  const { data, isLoading } = useCreditRiskMap(product, type, name, version)

  return <RiskMapEditor data={data} isLoading={isLoading} />
}
