import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = (
  product_type: string,
  type: string,
  name: string,
  version?: string
) => ["risk-mapping", product_type, type, name, version] as const

function useRiskMap(
  product_type: string,
  type: string,
  name: string,
  version?: string
) {
  const endpoint = endpoints.riskMapping.get(product_type, type, name, version)

  return useQuery(
    getQueryKey(product_type, type, name, version),
    endpoint.get,
    {
      select: (data) => data.data.data,
    }
  )
}

useRiskMap.getQueryKey = getQueryKey

export default useRiskMap
