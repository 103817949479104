import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material"
import { useState } from "react"

export default function FillDialog({
  action,
}: {
  action: (value: string) => void
}) {
  const [value, setValue] = useState<string | undefined>(undefined)
  const [showDialog, setShowDialog] = useState(false)
  const show = () => setShowDialog(true)
  const cancel = () => setShowDialog(false)
  const confirm = () => {
    if (value) action(value)
    setShowDialog(false)
    setValue(undefined)
  }
  return (
    <>
      <Button style={{ height: "100%" }} variant="contained" onClick={show}>
        Fill
      </Button>
      <Dialog open={showDialog}>
        <DialogTitle id="confirmation-dialog">
          {"Fill Grid with Value"}
        </DialogTitle>
        <DialogContent>
          <br />
          <Grid container spacing={10}>
            <Grid item>
              <TextField
                id="filler"
                label={"Fill With"}
                variant="outlined"
                onChange={(event) => setValue(event.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={cancel}>
            Cancel
          </Button>
          <Button
            disabled={value === undefined}
            variant="contained"
            onClick={confirm}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
