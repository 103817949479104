import { useQueries } from "@tanstack/react-query"
import type { PaydaysSearchResult } from "api"
import { endpoints } from "api"
import { PARTNERSHIPS } from "utils/constants"
import { isValidPartnership } from "utils/isValidPartnership"

interface UseGlobalSearchTermOptions {
  enabled: boolean
}

export function useGlobalSearchQueries(
  searchValue: string,
  { enabled }: UseGlobalSearchTermOptions
) {
  const queries = useQueries({
    queries: [
      {
        queryKey: ["search-company-by-id", searchValue],
        queryFn: endpoints.search.companyById(searchValue).get,
        enabled,
      },
      {
        queryKey: ["search-company-by-name", searchValue],
        queryFn: endpoints.search.companyByName(searchValue).get,
        // IMPROVEMENT: Could skip and save on the request if value matches the ID pattern
        // https://wagestream.atlassian.net/browse/PRET-270
        enabled,
      },
      {
        queryKey: ["search-user-by-id", searchValue],
        queryFn: endpoints.search.userById(searchValue).get,
        enabled,
      },
      {
        queryKey: ["search-employee-by-id", searchValue],
        // IMPROVEMENT: Could skip and save on the request if value matches the ID pattern
        // https://wagestream.atlassian.net/browse/PRET-270
        queryFn: endpoints.search.employeeById(searchValue).get,
        enabled,
      },
      {
        queryKey: ["search-employee-by-name", searchValue],
        queryFn: endpoints.search.employeeByName(searchValue).get,
        enabled,
      },
      {
        queryKey: ["search-employee-by-code", searchValue],
        queryFn: endpoints.search.employeeByCode(searchValue).get,
        enabled,
      },
      {
        queryKey: ["search-employee-by-work-email", searchValue],
        queryFn: endpoints.search.employeeByWorkEmail(searchValue).get,
        enabled,
      },
      {
        queryKey: ["search-user-by-email", searchValue],
        queryFn: endpoints.search.userByEmail(searchValue).get,
        enabled,
      },
    ],
  })

  // NOTE: Because these are merged in a specific order, there is a possibility that the
  // companyName results comes back before the companyId results and the spreading of these in a
  // set order might cause popping in the list of results. While this is a possibility - it's rare
  // that beyond the minimum amount of characters, there will be matches for the search term for
  // both the name and the UUID. Likewise, there is a requirement to show companies above employees
  // so if employees were to come back first, some popping is inevitable by design. In practice,
  // the company query is quicker than the employees query making the scenario where the employee
  // query comes back first and there is a long delay until the companies query comes back leading
  // to some popping - a theoretically possible but rare scenario in practice.
  const mergedData = queries
    .reduce<PaydaysSearchResult[]>(
      (acc, query) =>
        query.data?.data?.hits.length ? [...acc, ...query.data.data.hits] : acc,
      []
    )
    .reduce<PaydaysSearchResult[]>((acc, curr) => {
      const { entity, primary_key, priority, extra_info } = curr
      const existingEntity = acc.find(
        (item) => item.entity === entity && item.primary_key === primary_key
      )

      if (existingEntity) {
        existingEntity.priority = Math.max(existingEntity.priority, priority)
        return acc
      }

      if (entity === "company") {
        const partnership = isValidPartnership(extra_info)
          ? PARTNERSHIPS[extra_info]
          : extra_info
        curr.extra_info = partnership
      }

      return [...acc, curr]
    }, [])

  const areAllQueriesLoading = queries.every(({ isLoading }) => isLoading)
  const areSomeQueriesLoading = queries.some(({ isLoading }) => isLoading)

  const companyQueries = [queries[0], queries[2]]
  const userQueries = [queries[1], queries[3], queries[4], queries[5]]

  const isErrorLoadingCompanies = companyQueries.some(({ isError }) => isError)
  const isErrorLoadingEmployees = userQueries.some(({ isError }) => isError)

  return {
    data: areAllQueriesLoading ? undefined : mergedData,
    areAllQueriesLoading,
    areSomeQueriesLoading,
    isErrorLoadingCompanies,
    isErrorLoadingEmployees,
  }
}
