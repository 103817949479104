import type { GridColumns } from "@mui/x-data-grid"
import { RiskVersionButton } from "pages/Credit/components/RiskVersionButton"

export type RiskListRow = {
  key: string
  product: string
  type: string
  name: string
  versions: number[]
}

const COLUMNS: GridColumns<RiskListRow> = [
  {
    field: "product",
    headerName: "Product",
    width: 200,
  },
  {
    field: "type",
    headerName: "Type",
    width: 200,
  },
  {
    field: "name",
    headerName: "Name",
    width: 200,
  },
  {
    field: "actions",
    headerName: "Versions",
    width: 300,
    renderCell: ({ value }) => <RiskVersionButton data={value} />,
  },
]

export default COLUMNS
