import { Chip } from "@mui/material"
import { PARTNERSHIPS } from "utils/constants"
import { isValidPartnership } from "utils/isValidPartnership"

type Color =
  | "success"
  | "error"
  | "default"
  | "primary"
  | "secondary"
  | "info"
  | "warning"
  | undefined

type CompanyPartnershipProps = {
  small?: boolean
  value?: string
}

export function CompanyPartnership({ small, value }: CompanyPartnershipProps) {
  const color: Color = "secondary"

  const partnership = isValidPartnership(value) ? PARTNERSHIPS[value] : value
  return !partnership ? (
    <></>
  ) : (
    <Chip
      sx={{ minWidth: "7ch", px: small ? 1 : undefined }}
      size={small ? "small" : "medium"}
      color={color}
      label={partnership}
    />
  )
}
