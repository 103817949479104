import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { RiskMapping } from "api"
import { endpoints } from "api"
import type { AxiosError } from "axios"
import { useNotification } from "context/notification"
import useCreditRiskMapList from "pages/Credit/hooks/useCreditRiskMapList"

function useCreditRiskMapUpdate(
  product_type: string,
  mapping_type: string,
  mapping_name: string,
  options?: Partial<{
    onSuccess: () => void
  }>
) {
  const endpoint = endpoints.riskMapping.put(
    product_type,
    mapping_type,
    mapping_name
  )
  const queryClient = useQueryClient()
  const notify = useNotification()

  return useMutation(({ data }: { data: RiskMapping }) => endpoint.put(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(
        useCreditRiskMapList.getQueryKey(product_type)
      )
      options?.onSuccess?.()
    },
    onError: (e: AxiosError) => {
      const data = e?.response?.data as { message: string }
      notify.error(`Error: ${data?.message}`)
    },
  })
}

export default useCreditRiskMapUpdate
