import { Button, Grid } from "@mui/material"
import { useNavigate } from "react-router-dom"
import type { RiskListRow } from "pages/Credit/RiskMapList.columns"
import { useMemo } from "react"

export const RiskVersionButton = ({ data }: { data: RiskListRow }) => {
  const navigate = useNavigate()
  const versions = useMemo(() => data.versions.sort(), [data.versions])
  return (
    <Grid container spacing={1}>
      {versions.map((version: number, index: number) => (
        <Grid item key={version}>
          <Button
            variant={index + 1 === versions.length ? "contained" : "outlined"}
            onClick={() =>
              navigate(`${data.product}/${data.type}/${data.name}/${version}`)
            }
          >
            {version}
          </Button>
        </Grid>
      ))}
    </Grid>
  )
}
