import { Link } from "@mui/material"
import type { GridColumns } from "@mui/x-data-grid"
import JsonPopout from "components/JsonPopout/JsonPopout"
import { Link as RouterLink } from "react-router-dom"
import { routes } from "routes"
import { dateTimeColumnDefaultOptions } from "utils/columns"

const COLUMNS: GridColumns<{ company_id: string; name: string }> = [
  {
    field: "company_id",
    headerName: "Company Id",
    width: 300,
    renderCell: ({ id }) => (
      <Link component={RouterLink} to={routes.company({ id: String(id) })}>
        {id}
      </Link>
    ),
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "loan_live_date",
    headerName: "Loan Live Date (UTC)",
    width: 300,
    ...dateTimeColumnDefaultOptions(),
  },
  {
    field: "properties",
    headerName: "Credit Config",
    width: 300,
    renderCell: ({ value }) => (
      <JsonPopout
        btnVariant="outlined"
        title="Credit Config"
        text="View"
        json={value.credit}
      />
    ),
  },
]

export default COLUMNS
