import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material"
import type { AxiosError } from "axios"
import { ControlledTextField } from "components"
import ButtonWithConfirmation from "components/ButtonWithConfirmation/ButtonWithConfirmation"
import { useNotification } from "context/notification"
import {
  useServiceStatus,
  useUpdateServiceStatus,
} from "query-hooks/useUpdateServiceStatus"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"

type ServiceIncidentUpdate = {
  value: string
}

export default function UpdateServicesStatus() {
  const notify = useNotification()
  const [showDialog, setShowDialog] = useState(false)
  const { data } = useServiceStatus()
  const { mutate, isLoading } = useUpdateServiceStatus({
    onSuccess: () => {
      notify
        .that("service status update")
        .successfully("submitted for approval")
    },
    onError: (e: AxiosError) => {
      const data = e?.response?.data as { message: string }
      notify.error(`Error: ${data?.message}`)
    },
  })

  const { control, handleSubmit, reset } = useForm<ServiceIncidentUpdate>({
    defaultValues: {
      value: data?.data?.data || "",
    },
  })

  useEffect(() => {
    reset({ value: data?.data?.data || "" })
  }, [data, reset])

  const onSubmit = (data: ServiceIncidentUpdate) => {
    setShowDialog(false)
    mutate({ value: data.value })
  }

  const onErrors = (data: unknown) => {
    console.log(data)
    notify.error("Form validation error")
  }

  const confirm = () => {
    handleSubmit(onSubmit, onErrors)()
    return true
  }

  return (
    <>
      <Button
        disabled={isLoading}
        variant="contained"
        onClick={() => setShowDialog(true)}
      >
        Update Service Status
      </Button>
      <Dialog open={showDialog}>
        <DialogTitle>Service Status Update</DialogTitle>
        <br />
        <DialogContent>
          <Stack spacing={2} width={300}>
            <ControlledTextField
              control={control}
              label="ServiceStatus Description"
              name="value"
              rules={{ required: true }}
              fieldProps={{
                multiline: true,
                minRows: 4,
                maxRows: 10,
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)}>Cancel</Button>
          <ButtonWithConfirmation
            onConfirm={confirm}
            title="Confirm Status Update"
            message="Are you sure you want to request an update to the service status?"
            label="Update"
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
