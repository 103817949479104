export const CURRENCIES = {
  GBP: "GBP — Pounds Sterling (£)",
  EUR: "EUR — Euros (€)",
  USD: "USD — US Dollars ($)",
  AUD: "AUD — Australian Dollars ($)",
  CAD: "CAD — Canadian Dollars ($)",
}

export const TIMEZONES = {
  "Europe/London": { text: "Europe/London" },
  "Europe/Dublin": {
    text: "Europe/Dublin - use Europe/London instead",
    disabled: true,
  },
  "Europe/Amsterdam": { text: "Europe/Amsterdam" },
  "Europe/Madrid": { text: "Europe/Madrid" },
  "America/Anchorage": { text: "America/Anchorage" },
  "America/Chicago": { text: "America/Chicago" },
  "America/Denver": { text: "America/Denver" },
  "America/Los_Angeles": { text: "America/Los_Angeles" },
  "America/New_York": { text: "America/New_York" },
  "America/St_Johns": { text: "America/St_Johns" },
  "America/Halifax": { text: "America/Halifax" },
  "America/Glace_Bay": { text: "America/Glace_Bay" },
  "America/Moncton": { text: "America/Moncton" },
  "America/Goose_Bay": { text: "America/Goose_Bay" },
  "America/Blanc-Sablon": { text: "America/Blanc-Sablon" },
  "America/Toronto": { text: "America/Toronto" },
  "America/Iqaluit": { text: "America/Iqaluit" },
  "America/Atikokan": { text: "America/Atikokan" },
  "America/Winnipeg": { text: "America/Winnipeg" },
  "America/Resolute": { text: "America/Resolute" },
  "America/Rankin_Inlet": { text: "America/Rankin_Inlet" },
  "America/Regina": { text: "America/Regina" },
  "America/Swift_Current": { text: "America/Swift_Current" },
  "America/Edmonton": { text: "America/Edmonton" },
  "America/Cambridge_Bay": { text: "America/Cambridge_Bay" },
  "America/Inuvik": { text: "America/Inuvik" },
  "America/Creston": { text: "America/Creston" },
  "America/Dawson_Creek": { text: "America/Dawson_Creek" },
  "America/Fort_Nelson": { text: "America/Fort_Nelson" },
  "America/Whitehorse": { text: "America/Whitehorse" },
  "America/Dawson": { text: "America/Dawson" },
  "America/Vancouver": { text: "America/Vancouver" },
  "US/Central": {
    text: "US/Central - Use America/Chicago instead",
    disabled: true,
  },
  "Australia/Adelaide": { text: "Australia/Adelaide" },
  "Australia/Brisbane": { text: "Australia/Brisbane" },
  "Australia/Darwin": { text: "Australia/Darwin" },
  "Australia/Perth": { text: "Australia/Perth" },
  "Australia/Sydney": { text: "Australia/Sydney" },
}

export enum FUNDING_ENTITIES_ENUM {
  WS_LTD = "WS_LTD",
  SPV_2 = "SPV_2",
  SELF_FUNDED = "SELF_FUNDED",
  WS_IE = "WS_IE",
  WS_INC = "WS_INC",
  US_SPV_1 = "US_SPV_1",
  WS_CA_INC = "WS_CA_INC",
}

export const FUNDING_ENTITIES: Record<FUNDING_ENTITIES_ENUM, { text: string }> =
  {
    WS_LTD: { text: "Wagestream Ltd Funded" },
    SPV_2: { text: "Deutsche Bank Credit Funded" },
    SELF_FUNDED: { text: "Client Funded" },
    WS_IE: { text: "Wagestream EUR Ireland" },
    WS_INC: { text: "Wagestream Inc Funded" },
    US_SPV_1: { text: "HSBC Innovations Credit Funded" },
    WS_CA_INC: { text: "Wagestream Inc Canada Funded" },
  }

export const PAYMENT_COUNTRY_CODES: { [key: string]: { text: string } } = {
  GB: { text: "GB - United Kingdom" },
  ES: { text: "ES - Spain" },
  IE: { text: "IE - Ireland" },
  US: { text: "US - United States of America" },
  CA: { text: "CA - Canada" },
}

export const VALID_FUNDING_ENTITIES: {
  [payment_country_code: string]: {
    valid_funding_entities: Array<FUNDING_ENTITIES_ENUM>
  }
} = {
  GB: {
    valid_funding_entities: [
      FUNDING_ENTITIES_ENUM.WS_LTD,
      FUNDING_ENTITIES_ENUM.SPV_2,
      FUNDING_ENTITIES_ENUM.SELF_FUNDED,
    ],
  },
  ES: { valid_funding_entities: [FUNDING_ENTITIES_ENUM.WS_LTD] },
  IE: { valid_funding_entities: [FUNDING_ENTITIES_ENUM.WS_IE] },
}

export const PARTNER_STATES = {
  TRIAL: "Trial",
  ONBOARDING: "Onboarding",
  LIVE: "Live",
  DISABLED: "Disabled",
}

export const EMPLOYEE_CONTRACT_TYPES = {
  SALARY: "Salary",
  HOURLY: "Hourly",
  STANDARD_SHIFTS: "Standard shifts",
  OVERTIME_SHIFTS: "Overtime shifts",
  EXPENSES: "Expenses",
}

export const EMPLOYEE_STATES = {
  NEW: "New",
  INSTALLED: "Installed",
  PENDING: "Pending",
  ENROLLING: "Enrolling",
  ENROLLED: "Enrolled",
  ACTIVE: "Active",
  DISABLED: "Disabled",
}

// These map to class AllowListKey(str, Enum) in paydays-api
export const ALLOW_LIST_KEYS = {
  EMPLOYEE_CODE: "EMPLOYEE_CODE",
  FULL_NAME: "FULL_NAME",
  PAY_SCHEDULE_CODE: "PAY_SCHEDULE_CODE",
  WORK_EMAIL: "WORK_EMAIL",
  DIVISION: "DIVISION",
  EXTERNAL_ID: "EXTERNAL_ID",
}

export const PARTNERSHIPS = {
  wagestream: "Wagestream",
  osv: "MyFlexPay",
  lip: "Legion Instant Pay",
  harri: "Harri",
  mhr: "MHR",
  allocate: "Allocate",
  s4labour: "S4 Labour",
  oracle: "Oracle",
  roubler: "Roubler",
  zellis_zip: "MyView PayNow (Zellis ZIP)",
  zellis_sftp: "Zellis SFTP",
  people_first: "People First",
  adp_ihcm: "ADP iHCM",
  mfp: "myFlexPay",
  mfw: "myFlexWallet",
  frontline: "Frontline Absence Management",
}

export const ACCRUAL = {
  ADVANCE:
    "Advance — Employee is paid before the end of the current pay period",
  ALIGNED: "Aligned — Employee is paid at the end of a pay period",
  ARREARS:
    "Arrears — Employee is paid after the end of the previous pay period",
}

export const PERIODICITY = {
  MONTHLY: "Monthly",
  FORTNIGHT: "Fortnightly",
  WEEKLY: "Weekly",
  SEMIMONTHLY: "Semi-Monthly",
  FOUR_WEEKLY: "Four-Weekly",
}

export const WAGESTREAM_COMPANY_IDS = ["0d0b5a17-0a22-4194-b522-9b65a8503cb1"]

export const ENROLMENT_ISSUE_TYPES = {
  BANKING_MISSING:
    "The company is not using Fourth, and we have not received the original banking details",
  PREENROLLMENT_MISSING:
    "For unknown reasons, we never tried to pre-enroll the employee",
  PREENROLLMENT_FAILED:
    "We tried to pre-enroll but Railsbank failed, probably due to bad banking details",
  FOURTH_UPDATE_NOT_SUBMITTED:
    "For unknown reasons, we never tried to update Fourth",
  FOURTH_UPDATE_FAILED: "We tried to update Fourth but the update failed",
  PAYROLL_BLACKOUT:
    "The employee is within the payroll_blackout period for their company",
  AWAITING_WAGESTREAM_LEDGER_SETUP:
    "Attempted to enrol an employee without Wagestream ledger",
}
