import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { CreditConfig } from "api"
import { endpoints } from "api"
import type { AxiosError } from "axios"
import { useNotification } from "context/notification"

export default function useCreditCompanyConfig(
  options?: Partial<{
    onSuccess: () => void
  }>
) {
  const queryClient = useQueryClient()
  const notify = useNotification()

  return useMutation(
    ({ id, data }: { id: string; data: CreditConfig }) =>
      endpoints.companies.creditConfig(id).post(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["risk-mapping"])
        options?.onSuccess?.()
      },
      onError: (e: AxiosError) => {
        const data = e?.response?.data as { message: string }
        notify.error(`Error: ${data?.message}`)
      },
    }
  )
}
