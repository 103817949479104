import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = (product: string) =>
  ["risk-mapping", product] as const

function useRiskMapping(product_type: string) {
  const endpoint = endpoints.riskMapping.all(product_type)

  return useQuery(getQueryKey(product_type), endpoint.get, {
    select: (data) => data.data.data,
  })
}

useRiskMapping.getQueryKey = getQueryKey

export default useRiskMapping
