import styled from "@emotion/styled"
import { LoadingButton } from "@mui/lab"
import { Alert, DialogContent, Typography } from "@mui/material"
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog"
import { useState } from "react"

const ConfirmInput = styled.input`
  align-items: center;
  justify-content: center;
  width: 100%;
`

export default function ButtonWithConfirmation({
  children,
  onConfirm,
  message,
  title,
  label,
  variant,
  disabled,
  typeConfirm,
  endIcon,
  icon,
  style,
  loading,
}: {
  children?: React.ReactNode
  onConfirm: () => boolean
  message?: string
  title: string
  label: string
  variant?: "text" | "outlined" | "contained"
  disabled?: boolean
  typeConfirm?: boolean
  icon?: React.ReactNode
  endIcon?: React.ReactNode
  style?: React.CSSProperties
  loading?: boolean
}) {
  const [showDialog, setShowDialog] = useState(false)
  const [text, setText] = useState("")
  const cancel = () => setShowDialog(false)
  const confirm = () => {
    if (onConfirm()) {
      setShowDialog(false)
    }
  }
  return (
    <>
      <LoadingButton
        loading={loading}
        style={style}
        variant={variant}
        disabled={disabled}
        onClick={() => setShowDialog(true)}
        startIcon={icon}
        endIcon={endIcon}
      >
        {label}
      </LoadingButton>
      <ConfirmationDialog
        open={showDialog}
        title={title}
        onCancel={cancel}
        onConfirm={confirm}
        disabled={typeConfirm && text !== "CONFIRM"}
      >
        {message ? <Typography>{message}</Typography> : children}
        {typeConfirm && (
          <DialogContent>
            <Alert sx={{ my: 2 }} severity="warning">
              Type 'CONFIRM' to proceed:
              <ConfirmInput
                type="text"
                placeholder="CONFIRM"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </Alert>
          </DialogContent>
        )}
      </ConfirmationDialog>
    </>
  )
}
