import { Outlet } from "react-router"
import { useLocation } from "react-router-dom"
import { routes } from "routes"
import { Page, TabNav } from "components"

export default function CreditAdmin() {
  const { pathname } = useLocation()

  const navigationTabs = [{ label: "Risk Maps", href: routes.riskMapList() }]

  return (
    <Page
      headerTitle="Credit Hub"
      tabs={<TabNav tabs={navigationTabs} currentTab={pathname} />}
      scrollToTopButton
    >
      <Outlet />
    </Page>
  )
}
